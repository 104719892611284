import { TransformTag } from '@/utils'
import API from '@/api'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }
  return [
    {
      component: 'Section',
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Portal',
            variant: 'article',
            props: {
              api: API,
              theme: 'card',
            },
            blocks: {
              top: [
                {
                  component: 'Text',
                  data: {
                    content: TransformTag(get('content')),
                  },
                  props: {
                    style: 'padding-bottom: 3rem;',
                    class: 'text--medium',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: () => {
        if (!get('customFields.site-settings.show-article-cards')) return false
        if (!get('articles') && !get('content')) return false
        return true
      },
      props: {
        width: 'large',
        style: 'padding-block: 3rem 6rem;',
      },
      blocks: {
        default: [
          {
            component: 'Cards',
            props: {
              showReadMoreLabel: true,
            },
            data: {
              articles: get('articles'),
            },
            variant: 'breadcrumbs',
            blocks: {
              top: [
                {
                  component: 'Text',
                  data: {
                    content: get('content'),
                  },
                  props: {
                    class: 'news-card__content text-hero text',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('customFields.contact-cta.content'),
                  data: {
                    content: get('customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('customFields.contact-cta.button'),
                  data: {
                    label: get('customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'primary',
                    tag: 'router-link',
                    icon: ['fas', 'arrow-right'],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
